<template>
  <div class="preview-tile">
    <b-row>
      <b-col :class="`text-${layout}`">
        <b-skeleton animation="null" v-if="!name"></b-skeleton>
        <span v-else>{{ name }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col :class="`text-${layout}`">
        <span class="key-color key-orange"></span>
        <span class="key-color key-red"></span>
        <span class="key-color key-green"></span>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    props: ["layout", "name"],
  };
</script>

<style lang="scss" >
</style>
